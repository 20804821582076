:root {
  --baca: #dcc295;
}

.text-baca {
  color: var(--baca);
}

.bg-baca {
  background-color: var(--baca) !important;
}

.border-baca {
  border: 1px solid var(--baca) !important;
}

.active {
  color: var(--baca) !important;
  font-weight: bolder !important;
}

.item-img-container {
  border: 1px solid var(--baca);
  border-radius: 15px;
  overflow: hidden;
  aspect-ratio: 1/1;
}

.cart-table tr th,
.cart-table tr td {
  background-color: #fef6f3;
}

.custom-input:focus-visible {
  outline-color: transparent !important;
}

.filter-btn:hover,
.filter-btn:focus {
  border-color: transparent !important;
  background-color: var(--baca) !important;
}

.filter-btn:hover .filter-icon,
.filter-btn:focus .filter-icon {
  color: white !important;
}

body {
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

/* Fallback.css */
@keyframes jiggle {
  0%,
  100% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
}

.jiggle {
  animation: jiggle 0.5s infinite;
}

@media (min-width: 767.98px) {
  .footer-logo {
    max-width: 50%;
  }
}
